import React from 'react';
import { SEO } from './exports';
import { useTranslation } from '../contexts/TranslationContext';

export const XRechnungInfo = () => {
  const { t } = useTranslation();
  const [openFaqs, setOpenFaqs] = React.useState<boolean[]>(new Array(13).fill(false));

  const toggleFaq = (index: number) => {
    setOpenFaqs(prev => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const faqData = [
    {
      question: "Ist eine PDF-Rechnung eine E-Rechnung?",
      answer: "Nein, eine PDF ist nur dann eine E-Rechnung, wenn sie zusätzlich ein XML-Datenformat enthält."
    },
    {
      question: "Kann ich weiterhin Papierrechnungen verwenden?",
      answer: "Nur bis Ende 2026 und nur mit Zustimmung des Empfängers."
    },
    {
      question: "Muss ich spezielle Software verwenden?",
      answer: "Ja, die E-Rechnung erfordert zertifizierte Software, die gesetzliche Anforderungen erfüllt."
    },
    {
      question: "Ist der XRechnung Viewer wirklich kostenlos?",
      answer: "Ja, unser XRechnung Viewer ist vollkommen kostenlos und erfordert keine Registrierung. Wenn Sie jedoch eine Software suchen, die XRechnungen automatisch erstellt und speichert, können Sie Robaws als professionelle Lösung in Betracht ziehen."
    },
    {
      question: "Welche Formate werden unterstützt?",
      answer: "Unser Viewer unterstützt das standardisierte XML-Format der XRechnung. Sollten Sie Rechnungen in weiteren Formaten wie UBL, ZUGFeRD, EDI, FakturaX benötigen, bietet Robaws erweiterte Funktionen."
    },
    {
      question: "Kann ich auch Rechnungen über PEPPOL senden?",
      answer: "Nein, unser Tool bietet keine Unterstützung für das Senden von XRechnungen über PEPPOL. Für Unternehmen, die PEPPOL in umfangreichere Buchhaltungsprozesse integrieren möchten, empfehlen wir Robaws."
    },
    {
      question: "Werden meine Daten gespeichert?",
      answer: "Nein, Ihre Daten werden weder gespeichert noch weitergegeben. Wenn Sie jedoch eine zentrale Datenverwaltungslösung suchen, ist Robaws eine gute Option."
    },
    {
      question: "Kann ich XRechnungen direkt bearbeiten?",
      answer: "Nein, unser Viewer dient nur der Anzeige und Validierung. Wenn Sie XRechnungen erstellen oder bearbeiten möchten, sollten Sie eine Software wie Robaws nutzen."
    },
    {
      question: "Welche Anforderungen muss meine XRechnung erfüllen?",
      answer: "Ihre XRechnung muss dem EN-16931-Standard entsprechen, einschließlich Pflichtfelder wie Rechnungsnummer, Steuersätze und Lieferdatum. Mit Robaws können Sie sicherstellen, dass alle Anforderungen automatisch erfüllt werden."
    },
    {
      question: "Wie kann ich eine XRechnung in ein PDF umwandeln?",
      answer: "Nach dem Hochladen Ihrer XRechnung können Sie sie direkt als PDF speichern. Für wiederkehrende Konvertierungen oder größere Mengen empfiehlt sich eine automatisierte Lösung wie Robaws."
    },
    {
      question: "Ist der Viewer auch für mobile Geräte geeignet?",
      answer: "Ja, unser Viewer ist vollständig für mobile Geräte optimiert."
    },
    {
      question: "Kann ich mehrere XRechnungen gleichzeitig hochladen?",
      answer: "Nein, der Viewer unterstützt aktuell nur eine Datei pro Sitzung. Für die Verarbeitung mehrerer Rechnungen gleichzeitig bietet Robaws Stapelverarbeitungsfunktionen."
    },
    {
      question: "Welche Fehler kann der Viewer erkennen und beheben?",
      answer: "Unser Viewer erkennt Fehler wie fehlende Pflichtfelder oder ungültige Datenformate. Für komplexere Validierungen und automatische Korrekturen ist Robaws die ideale Lösung."
    }
  ];

  return (
    <>
      <SEO 
        title="E-Rechnungs-Pflicht Deutschland 2025 - XRechnung & ZUGFeRD Guide"
        description="Ab 2025 sind E-Rechnungen für B2B-Transaktionen Pflicht. Erfahren Sie alles über XRechnung, ZUGFeRD und wie Sie Ihr Unternehmen vorbereiten können."
        canonicalUrl="https://xrechnungzentrale.de/xrechnung-info"
        type="article"
        faqSchema={{
          questions: faqData
        }}
      />

      {/* Hero Section with Gradient Overlay */}
      <div className="relative h-[60vh] min-h-[400px] w-full overflow-hidden rounded-xl z-0">
        <img
          src="/assets/xml-rechnung-einfach-erklaert.png"
          alt="XML Rechnung einfach erklärt"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900/70 to-blue-900/90" />
        <div className="absolute inset-0 flex items-center">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white max-w-4xl">
              E-Rechnungs-Pflicht Deutschland 2025
            </h1>
            <p className="mt-6 text-xl text-blue-50 max-w-2xl">
              Ab dem 1. Januar 2025 sind elektronische Rechnungen für alle B2B-Transaktionen verpflichtend. Erfahren Sie, wie Sie Ihr Unternehmen vorbereiten können.
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="bg-gray-50">
        <div className="container mx-auto px-4 py-12">
          <div className="max-w-3xl mx-auto">
            {/* Quick Info Cards */}
            <div className="grid md:grid-cols-3 gap-6 mb-16">
              <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                <div className="text-blue-600 mb-3">
                  <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 className="font-semibold text-gray-900">Deadline</h3>
                <p className="text-gray-600 text-sm mt-2">1. Januar 2025</p>
              </div>
              <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                <div className="text-blue-600 mb-3">
                  <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                </div>
                <h3 className="font-semibold text-gray-900">Betroffen</h3>
                <p className="text-gray-600 text-sm mt-2">Alle B2B Unternehmen</p>
              </div>
              <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                <div className="text-blue-600 mb-3">
                  <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 className="font-semibold text-gray-900">Format</h3>
                <p className="text-gray-600 text-sm mt-2">XRechnung oder ZUGFeRD</p>
              </div>
            </div>

            {/* Article Content */}
            <article className="prose prose-h2:mt-4 prose-h3:m-0 prose-lg max-w-none">
              {/* Keep your existing content sections but with updated styling */}
              <section className="bg-white rounded-t-xl shadow-sm p-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Was ist eine E-Rechnung?</h2>
                <p className="text-gray-600 leading-relaxed mb-4">
                  Eine E-Rechnung ist mehr als nur eine PDF-Rechnung per E-Mail. Es handelt sich um eine Rechnung, die in einem strukturierten, maschinenlesbaren Format erstellt, versandt und empfangen wird. Dieses Format ermöglicht die automatische Verarbeitung und muss den Anforderungen der EU-Norm EN 16931 entsprechen. Eine E-Rechnung besteht aus einer XML-Datei, die alle Rechnungsdaten in einer spezifischen Struktur enthält (UBL oder CII), die von Computern gelesen werden kann.
                </p>
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-6 rounded-lg">
                  <p className="text-yellow-800">
                    <strong>Wichtig:</strong> Eine PDF-Rechnung, die per E-Mail verschickt wird, ist keine E-Rechnung im Sinne der neuen Gesetzgebung. PDF-Rechnungen sind lediglich digitale Kopien von Papierdokumenten und erfüllen nicht die Anforderung der Maschinenlesbarkeit.
                  </p>
                </div>
              </section>

              <section className="bg-white shadow-sm p-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Wer ist betroffen?</h2>
                <p className="text-gray-600 leading-relaxed mb-4">
                  Die E-Rechnungspflicht gilt ab dem 1. Januar 2025 für alle Unternehmen in Deutschland, die mit anderen Unternehmen (B2B) Geschäfte tätigen. Auch kleinere Handwerksbetriebe und Dienstleister müssen bis 2028 vollständig auf E-Rechnungen umstellen.
                </p>
                
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-6 rounded-lg">
                  <h3 className="font-semibold text-yellow-800 mb-2">Wichtige Ausnahmen</h3>
                  <ul className="list-disc list-inside text-yellow-700 space-y-2">
                    <li>Für Privatkunden (B2C) bleibt die Zustimmung des Kunden zum Versand einer E-Rechnung erforderlich.</li>
                    <li>Rechnungen in Papierform sind bis Ende 2026 noch erlaubt, sofern der Empfänger zustimmt.</li>
                  </ul>
                </div>
              </section>

              <section className="bg-white shadow-sm p-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Ich habe eine XML-Rechnung erhalten – was nun?</h2>
                <p className="text-gray-600 leading-relaxed mb-6">
                  Eine XML-Rechnung, wie z. B. eine XRechnung, kann auf den ersten Blick verwirrend wirken, da sie nicht wie eine traditionelle Rechnung lesbar ist. Hier sind einige Lösungen, was Sie damit tun können:
                </p>

                <div className="space-y-6">
                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Nutzen Sie einen XRechnung-Viewer</h3>
                    <p className="text-gray-600">
                      Verwenden Sie ein spezialisiertes Tool wie einen kostenlosen XRechnung-Viewer, um die XML-Datei anzuzeigen und sicherzustellen, dass alle Daten korrekt sind.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Validieren Sie die Rechnung</h3>
                    <p className="text-gray-600">
                      Überprüfen Sie die Rechnung auf ihre Konformität mit den gesetzlichen Vorgaben (EN 16931). Dies kann mit validierungsfähiger Software erfolgen, die mögliche Fehler erkennt und Sie darauf hinweist.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Integrieren Sie sie in Ihre Buchhaltungssoftware</h3>
                    <p className="text-gray-600">
                      Wenn Sie eine Buchhaltungssoftware verwenden, prüfen Sie, ob diese XML-Rechnungen direkt importieren und verarbeiten kann. Viele moderne Lösungen bieten diese Funktionalität, wodurch die Rechnung direkt in Ihre Finanzprozesse integriert werden kann.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Manuelle Archivierung und Weiterleitung</h3>
                    <p className="text-gray-600">
                      Speichern Sie die XML-Datei an einem sicheren Ort und leiten Sie sie gegebenenfalls an Ihre Buchhaltungsabteilung oder den zuständigen Empfänger weiter.
                    </p>
                  </div>
                </div>

                <div className="mt-8 p-6 bg-blue-50 rounded-lg border border-blue-100">
                  <p className="text-blue-800 mb-4">
                    <strong>Tipp:</strong> Wenn Sie die XML-Rechnung einfach und schnell lesen möchten, probieren Sie unseren kostenlosen XRechnung-Viewer aus.
                  </p>
                  <a 
                    href="/" 
                    className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
                  >
                    Jetzt kostenlosen XRechnung-Viewer nutzen
                  </a>
                </div>
              </section>

              <section className="bg-white shadow-sm p-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Vorteile von E-Rechnungen</h2>
                <p className="text-gray-600 leading-relaxed mb-6">
                  E-Rechnungen bieten Unternehmen zahlreiche Vorteile:
                </p>
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Kostenersparnis</h3>
                    <p className="text-gray-600">
                      Keine Ausgaben für Papier, Druck, Toner, Umschläge und Porto.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Zeitersparnis</h3>
                    <p className="text-gray-600">
                      E-Rechnungen werden digital und direkt versandt sowie verarbeitet, wodurch der Verwaltungsaufwand sinkt.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Schnellere Zahlungen</h3>
                    <p className="text-gray-600">
                      Durch die schnellere Verarbeitung werden E-Rechnungen auch schneller bezahlt, was die Liquidität verbessert.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Weniger Fehler</h3>
                    <p className="text-gray-600">
                      Automatisierte Prozesse reduzieren menschliche Fehler.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Umweltfreundlichkeit</h3>
                    <p className="text-gray-600">
                      Weniger Papierverbrauch schont die Umwelt und reduziert den ökologischen Fußabdruck.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Bessere Transparenz</h3>
                    <p className="text-gray-600">
                      Digital gespeicherte Rechnungen lassen sich leicht finden und überprüfen.
                    </p>
                  </div>
                </div>
              </section>

              <section className="bg-white shadow-sm p-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Wie bereite ich meinen Betrieb auf die E-Rechnung vor?</h2>
                <div className="space-y-6">
                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Prüfen Sie Ihre Software</h3>
                    <p className="text-gray-600">
                      Verfügt Ihre Handwerkersoftware oder Buchhaltungssoftware über eine E-Rechnungs-Funktion (z. B. XRechnung, UBL, CII, ZUGFeRD)?
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Schulen Sie Ihr Team</h3>
                    <p className="text-gray-600">
                      Stellen Sie sicher, dass Ihre Mitarbeiter mit den neuen Prozessen vertraut sind.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">Kommunizieren Sie</h3>
                    <p className="text-gray-600">
                      Informieren Sie Ihre Kunden und Lieferanten frühzeitig über die Umstellung.
                    </p>
                  </div>
                </div>
              </section>

              <section className="bg-white shadow-sm p-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Welche Formate für E-Rechnungen gibt es in Deutschland?</h2>
                <div className="space-y-6">
                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">XRechnung</h3>
                    <p className="text-gray-600">
                      Der offizielle Standard für E-Rechnungen in Deutschland, speziell für den öffentlichen Sektor (B2G). Die Daten werden ausschließlich semantisch in einer XML-Datei dargestellt. XRechnungen sind nicht direkt menschenlesbar, aber vollständig maschinenverarbeitbar.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">ZUGFeRD</h3>
                    <p className="text-gray-600">
                      Eine hybride E-Rechnung, die ein maschinenlesbares XML-Dokument mit einer visuellen PDF kombiniert. So ist die Rechnung sowohl für Menschen als auch für Maschinen lesbar. ZUGFeRD wird auch als XRechnung 2.0 bezeichnet und ist mit dem französischen Factur-X vergleichbar.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-lg border border-gray-100">
                    <h3 className="font-semibold text-gray-900 mb-3">EDI (Electronic Data Interchange)</h3>
                    <p className="text-gray-600">
                      Hierbei werden die Rechnungsdaten direkt zwischen den Computersystemen von Unternehmen ausgetauscht. Dies ist effizient, erfordert jedoch spezielle technische Vereinbarungen. Ob dieses Format nach 2028 weiterhin genutzt werden darf, ist noch unklar.
                    </p>
                  </div>
                </div>
              </section>

              <section className="bg-white shadow-sm p-8">
                <h2 className="text-3xl font-bold text-gray-900">Wie erstellt man eine XRechnung?</h2>
                <p className="text-gray-600 leading-relaxed mb-8">
                  Es gibt mehrere Möglichkeiten, eine XRechnung zu erstellen, je nach den Anforderungen Ihres Unternehmens und der Anzahl der zu bearbeitenden Rechnungen. Die Hauptmethoden sind die Nutzung spezialisierter Buchhaltungssoftware, Online-Generatoren oder die Konvertierung von PDF-Rechnungen in das XRechnungsformat.
                </p>

                <div className="grid gap-6">
                  {/* Option 1 - Green background */}
                  <div className="bg-green-50 rounded-xl border border-green-100 overflow-hidden">
                    <div className="p-6 border-b border-green-100">
                      <h3 className="text-xl font-semibold text-gray-900">Option 1: Erstellung mit Rechnungsprogramm</h3>
                    </div>
                    <div className="p-6">
                      <p className="text-gray-600 mb-6">
                        Die gängigste Methode zur Erstellung von XRechnungen ist die Nutzung spezialisierter Software. Führende Anbieter haben XRechnung bereits in ihren Standardleistungen integriert, sodass Sie Rechnungen mit wenigen Klicks automatisch erstellen und per E-Mail oder über Peppol versenden können.
                      </p>
                      <div className="bg-white rounded-lg p-6 border border-green-100">
                        <h4 className="font-semibold text-gray-900 mb-4">Vorteile:</h4>
                        <ul className="space-y-3 text-gray-600">
                          <li className="flex items-start">
                            <span className="mr-3">•</span>
                            <span><b>Automatisierung:</b> Rechnungen werden schnell und fehlerfrei generiert.</span>
                          </li>
                          <li className="flex items-start">
                            <span className="mr-3">•</span>
                            <span><b>Archivierung:</b> Viele Softwarelösungen bieten eine direkte Archivierungsfunktion an.</span>
                          </li>
                          <li className="flex items-start">
                            <span className="mr-3">•</span>
                            <span><b>Skalierbarkeit:</b> Ideal für Unternehmen, die eine hohe Anzahl von Rechnungen verarbeiten.</span>
                          </li>
                          <li className="flex items-start">
                            <span className="mr-3">•</span>
                            <span><b>Zeitsparer:</b> Wenn die Software zudem in der Lage ist, Artikel, Kunden, Lieferanten und Aufträge zu verknüpfen, wird sie zu einem unverzichtbaren Zeitsparer für jedes Unternehmen.</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* Option 2 - Red background */}
                  <div className="bg-red-50 rounded-xl border border-red-100 overflow-hidden">
                    <div className="p-6 border-b border-red-100">
                      <h3 className="text-xl font-semibold text-gray-900">Option 2: Online-Generatoren</h3>
                    </div>
                    <div className="p-6">
                      <p className="text-gray-600 mb-6">
                        Online-Generatoren sind eine praktische Alternative, insbesondere für kleine Unternehmen oder gelegentliche Rechnungen. Mit diesen Tools können Sie XRechnungen erstellen, die direkt heruntergeladen werden können.
                      </p>
                      <div className="bg-white rounded-lg p-6 border border-red-100">
                        <h4 className="font-semibold text-gray-900 mb-4">Nachteile:</h4>
                        <ul className="space-y-3 text-gray-600">
                          <li className="flex items-start">
                            <span className="mr-3">•</span>
                            <span><b>Limitierungen:</b> Kostenlose Online-Generatoren beschränken oft die Anzahl der Rechnungen.</span>
                          </li>
                          <li className="flex items-start">
                            <span className="mr-3">•</span>
                            <span><b>Designoptionen:</b> Bei hybriden Rechnungen wie ZUGFeRD sind die Gestaltungsmöglichkeiten begrenzt.</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* Option 3 */}
                  <div className="bg-gray-50 rounded-xl border border-gray-100 overflow-hidden">
                    <div className="p-6 border-b border-gray-100">
                      <h3 className="text-xl font-semibold text-gray-900">Option 3: PDF-Rechnung in XRechnung konvertieren</h3>
                    </div>
                    <div className="p-6">
                      <p className="text-gray-600 mb-6">
                        Eine weitere Möglichkeit ist die Konvertierung bestehender PDF-Rechnungen in das XRechnungsformat. Einige Dienstleister bieten diese Funktion kostenpflichtig an.
                      </p>
                      <div className="bg-white rounded-lg p-6 border border-gray-100">
                        <h4 className="font-semibold text-gray-900 mb-4">Ablauf:</h4>
                        <ul className="space-y-3 text-gray-600">
                          <li className="flex items-start">
                            <span className="mr-3">1.</span>
                            <span>Laden Sie die PDF-Rechnung hoch.</span>
                          </li>
                          <li className="flex items-start">
                            <span className="mr-3">2.</span>
                            <span>Der Dienstleister konvertiert die Daten in eine maschinenlesbare XML-Datei.</span>
                          </li>
                          <li className="flex items-start">
                            <span className="mr-3">3.</span>
                            <span>Sie erhalten die XRechnung und können diese an den Empfänger senden.</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* Requirements Section */}
              <section className="bg-white rounded-b-xl shadow-sm overflow-hidden">
                <div className="p-6">
                  <h2 className="text-3xl font-bold text-gray-900">Was muss eine XRechnung enthalten?</h2>
                    <p className="text-gray-600 mb-6">
                      Grundsätzlich muss eine XRechnung dieselben Pflichtangaben wie eine klassische Rechnung enthalten:
                    </p>
                  <div className="bg-white rounded-lg p-6 border border-gray-100">
                    <ul className="space-y-3 text-gray-600">
                      <li className="flex items-start">
                        <span className="mr-3">•</span>
                        <span>Name und Adresse des Auftraggebers und Auftragnehmers</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3">•</span>
                        <span>Steuer- oder Umsatzsteuer-Identifikationsnummer (USt-ID)</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3">•</span>
                        <span>Rechnungsdatum und Rechnungsnummer</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3">•</span>
                        <span>Art, Menge und Zeitpunkt der Lieferung oder Leistung</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3">•</span>
                        <span>Nettobetrag und Steuersatz</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3">•</span>
                        <span>Mehrwertsteuerbetrag</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3">•</span>
                        <span>Gesamtbetrag</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3">•</span>
                        <span>Zahlungsbedingungen und Fälligkeitsdatum</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3">•</span>
                        <span>Bankverbindung oder andere Zahlungsinformationen</span>
                      </li>
                    </ul>
                  </div>
                    <p className="mt-6 text-gray-600">
                      Zusätzlich enthält eine XRechnung spezifische Informationen wie die Leitweg-ID des Rechnungsempfängers. Diese bis zu 40 Zeichen lange Zeichenkette wird im Feld „Leitweg-ID" angegeben und ermöglicht die eindeutige elektronische Adressierung und Weiterleitung der Rechnung.
                    </p>
                  </div>
              </section>

              <section className="bg-white rounded-b-xl shadow-sm p-8 mt-8 mb-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Häufig gestellte Fragen</h2>
                <div className="space-y-4">
                  {faqData.map((faq, index) => (
                    <div key={index} className="border border-gray-200 rounded-lg overflow-hidden">
                      <button
                        onClick={() => toggleFaq(index)}
                        className="w-full flex justify-between items-center p-4 text-left bg-gray-50 hover:bg-gray-100 transition-colors"
                      >
                        <h3 className="font-semibold text-gray-900">{faq.question}</h3>
                        <svg
                          className={`w-5 h-5 transform transition-transform ${
                            openFaqs[index] ? 'rotate-180' : ''
                          }`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                      {openFaqs[index] && (
                        <div className="p-4 bg-white">
                          <p className="text-gray-600">{faq.answer}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </section>

              {/* Call to Action */}
              <div className="bg-gradient-to-br from-blue-600 to-blue-700 rounded-xl shadow-lg p-8 mt-16 text-white">
                <h2 className="text-3xl font-bold mb-4 text-white">Jetzt handeln!</h2>
                <p className="text-blue-50 mb-8">
                  Bereiten Sie Ihr Unternehmen auf die E-Rechnungspflicht vor. Mit Robaws haben Sie einen starken Partner an Ihrer Seite.
                </p>
                <a 
                  href="https://robaws.com/de/demo-anfordern?utm_source=xrechnungzentrale.de" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-flex items-center gap-2 bg-white text-blue-600 font-medium px-8 py-3 rounded-lg transition-colors hover:bg-blue-50"
                >
                  Kostenlose Demo anfordern
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </a>
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};
