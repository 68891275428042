import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { label: 'XRechnung Viewer', path: '/' },
    { label: 'XRechnung Info', path: '/xrechnung-info' },
    { 
      label: 'Mehr über Robaws', 
      path: 'https://robaws.com/de/funktionen/rechnungsprogramm?utm=xrechnungzentrale.de',
      external: true 
    }
  ];

  const navButtonStyle = "px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900 bg-gray-50 hover:bg-gray-100 rounded-md transition-colors z-50";

  return (
    <>
      {/* Desktop Navigation */}
      <div className="hidden lg:flex items-center space-x-4 z-50">
        {navItems.map((item) => (
          item.external ? (
            <a
              key={item.path}
              href={item.path}
              target="_blank"
              rel="noopener noreferrer"
              className={navButtonStyle}
            >
              {item.label}
            </a>
          ) : (
            <Link
              key={item.path}
              to={item.path}
              className={navButtonStyle}
            >
              {item.label}
            </Link>
          )
        ))}
      </div>

      {/* Mobile Navigation */}
      <div className="lg:hidden z-50">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 text-gray-600 hover:text-gray-900"
        >
          {isOpen ? (
            <X className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </button>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="absolute top-full right-0 w-64 mt-2 py-2 bg-white rounded-lg shadow-lg border border-gray-100">
            {navItems.map((item) => (
              item.external ? (
                <a
                  key={item.path}
                  href={item.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => setIsOpen(false)}
                >
                  {item.label}
                </a>
              ) : (
                <Link
                  key={item.path}
                  to={item.path}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => setIsOpen(false)}
                >
                  {item.label}
                </Link>
              )
            ))}
          </div>
        )}
      </div>
    </>
  );
};