import React from 'react';
import { Receipt, Building2, User, FileText, Calendar, CreditCard, Paperclip } from 'lucide-react';
import { Attachment, XRechnungData, PaymentMeans } from '../types/xrechnung';
import { useTranslation } from '../contexts/TranslationContext';

interface Props {
  invoice: XRechnungData;
}

const InvoiceDisplay: React.FC<Props> = ({ invoice }) => {
  const { t } = useTranslation();

  const formatCurrency = (amount: number | undefined) => {
    if (amount === undefined) return '-';
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: invoice.currencyCode
    }).format(amount);
  };

  const handleDownload = (attachment: Attachment) => {
    try {
      // Decode base64 and handle unicode characters correctly
      const binaryString = atob(attachment.content);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      
      // Create blob with the correct type
      const blob = new Blob([bytes], { type: attachment.mimeType });
      const url = URL.createObjectURL(blob);
      
      // Create and trigger download
      const a = document.createElement('a');
      a.href = url;
      a.download = attachment.filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading attachment:', error);
      // You might want to show an error message to the user here
    }
  };

  const documentTypeTranslations: Record<string, string> = {
    '326': 'Teilrechnung',
    '380': 'Rechnung',
    '384': 'Rechnungskorrektur',
    '389': 'Selbstfakturierte Rechnung',
    '381': 'Gutschrift',
    '875': 'Abschlagsrechnung (Bauleistung)',
    '876': 'Teilschlussrechnung (Bauleistung)',
    '877': 'Schlussrechnung (Bauleistung)'
  };

  const PaymentSection: React.FC<{ payment: PaymentMeans; invoice: XRechnungData }> = ({ payment, invoice }) => {
    const { t } = useTranslation();

    // Payment type mapping
    const paymentTypes: { [key: string]: string } = {
      '1': 'Barzahlung',
      '10': 'Barzahlung bei Lieferung',
      '20': 'Scheck',
      '30': 'Elektronische Zahlung',
      '31': 'Debitkarte',
      '42': 'Banküberweisung',
      '48': 'Kreditkarte',
      '49': 'Lastschrift',
      '50': 'Gutschrift',
      '58': 'SEPA-Überweisung',
      '59': 'SEPA-Lastschrift',
      '97': 'Aufrechnung'
    };

    // Get the payment note from either source
    const paymentNote = invoice.paymentTerms?.note || payment.note;

    return (
      <div className="space-y-2">
        <div className="flex items-center">
          <span className="text-sm text-gray-500">{t('zahlungsart')}: </span>
          <span className="text-sm font-medium ml-1">
            {payment.type ? paymentTypes[payment.type] || `Zahlungsart ${payment.type}` : '-'}
          </span>
        </div>
        {paymentNote && (
          <div className="flex items-center">
            <span className="text-sm text-gray-500">Zahlungshinweis: </span>
            <span className="text-sm font-medium ml-1">{paymentNote}</span>  
          </div>
        )}
        {payment.bankAccount?.iban && (
          <div className="flex items-center">
            <span className="text-sm text-gray-500">IBAN: </span>
            <span className="text-sm font-medium ml-1">{payment.bankAccount.iban}</span>
          </div>
        )}
        {payment.additionalBankAccounts && payment.additionalBankAccounts.length > 0 && (
          <div className="flex items-center">
            <span className="text-sm text-gray-500">Zusätzliche IBANs: </span>
            <span className="text-sm font-medium ml-1">{payment.additionalBankAccounts.map(acc => acc.iban).join(', ')}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 space-y-6">
      <div className="flex items-center justify-between border-b pb-4">
        <div className="flex items-center space-x-3">
          <Receipt className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">{t('rechnungsDetails')}</h2>
        </div>

      </div>

      <div className="flex flex-col md:flex-row gap-x-16 gap-y-6">
        <div className="md:w-5/8 space-y-4">
          <div className="flex items-start space-x-3">
            <Building2 className="h-5 w-5 text-gray-400 mt-1 flex-shrink-0" />
            <div className="min-w-0 w-full">
              <h3 className="text-sm font-medium text-gray-500 mb-1">
                {t('verkaeuferInfo')}
              </h3>
              <div className="flex items-center gap-1 text-gray-900 prose-sm">
                <span className="text-[10px] font-bold break-words w-full">{invoice.seller?.name} - {invoice.seller?.address?.street} {invoice.seller?.address?.postcode} {invoice.seller?.address?.city} - {invoice.seller?.vatNumber}</span>
              </div>
            </div>
          </div>
          <div className="flex items-start space-x-3">
            <User className="h-5 w-5 text-gray-400 mt-1" />
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1">
                {t('kaeuferInfo')}
              </h3>
              <p className="text-gray-600">{invoice.buyer?.name}</p>
              {invoice.buyer?.contact && (
                <div className="text-gray-600">
                  <p>{invoice.buyer.contact.name}</p>
                </div>
              )}
              <p className="text-sm break-words w-full text-gray-600">
                {invoice.buyer?.address?.street}, {invoice.buyer?.address?.postcode}, {invoice.buyer?.address?.city}
              </p>
              {invoice.buyer?.vatNumber && (
                <p className="text-sm text-gray-600 mt-1">
                  {invoice.buyer.vatNumber}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="md:w-3/8 space-y-4">
          <div className="flex items-start space-x-3">
            <Calendar className="h-5 w-5 text-gray-400 mt-1" />
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1">Data</h3>
              <p className="text-gray-600">Rechnungsdatum: {invoice.issueDate}</p>
              <p className="text-sm text-gray-600 mt-1">
                Fälligkeitsdatum: {invoice.dueDate}
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-3">
            <User className="h-5 w-5 text-gray-400 mt-1" />
            <div>
                <h3 className="text-sm font-medium text-gray-500 mb-1">Ansprechpartner</h3>
                {invoice.seller?.contact ? (
                  <div>
                      <p className="text-gray-600">{invoice.seller.contact.name}</p>
                      {invoice.seller.contact.telephone && (
                        <p className="text-sm text-gray-600 mt-1">
                          Tel: {invoice.seller.contact.telephone}
                        </p>
                      )}
                      {invoice.seller.contact.email && (
                        <p className="text-sm text-gray-600 mt-1">
                          Email: {invoice.seller.contact.email}
                        </p>
                      )}
                  </div>
                 ) : (
                <p className="text-gray-600">Keine Ansprechpartner angegeben</p>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-1 px-3 py-1">
        <span className="text-xl font-bold text-gray-900">
          {invoice.documentType && documentTypeTranslations[invoice.documentType] || 'Rechnung'}
        </span>
        <span className="text-xl font-bold text-gray-900">
          Nr. {invoice.invoiceNumber}
        </span>
      </div>
      {invoice.items && invoice.items.length > 0 && (
        <div className="mt-6">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('beschreibung')}
                </th>
                <th className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('menge')}
                </th>
                <th className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('einzelpreis')}
                </th>
                <th className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('ustSatz')}
                </th>
                <th className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('gesamtpreis')}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {invoice.items.map((item) => (
                <React.Fragment key={item.id}>
                  <tr>
                    <td className="px-3 py-4 text-sm text-gray-900">
                      {item.name || item.description}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-900 text-right">{item.quantity}</td>
                    <td className="px-3 py-4 text-sm text-gray-900 text-right">{formatCurrency(item.unitPrice)}</td>
                    <td className="px-3 py-4 text-sm text-gray-900 text-right">{item.vatRate}%</td>
                    <td className="px-3 py-4 text-sm text-gray-900 text-right">{formatCurrency(item.lineTotal)}</td>
                  </tr>
                  {item.name && item.description && (
                    <tr>
                      <td colSpan={5} className="px-3 py-2 text-sm text-gray-600 border-b">
                        - {item.description}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} className="px-3 py-4 text-sm font-medium text-gray-900 text-right">
                  {t('gesamtpreis')}
                </td>
                <td className="px-3 py-4 text-sm font-medium text-gray-900 text-right">
                  {formatCurrency(invoice.totalAmount)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}


      <div className="flex items-start space-x-3">
        <CreditCard className="h-5 w-5 text-gray-400 mt-1" />
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-2">{t('zahlungsInfo')}</h3>
          {invoice.paymentMeans && <PaymentSection payment={invoice.paymentMeans} invoice={invoice} />}
          <p className="text-sm text-gray-600 mt-1">
            {invoice.paymentTerms?.skontoTerms?.map((term, index) => (
              <span key={index} className="block ml-2">
                {term.percentage}% Skonto bei Zahlung innerhalb von {term.days} Tagen
                {term.baseAmount ? ` (Basisbetrag: ${formatCurrency(term.baseAmount)})` : ''}
              </span>
            ))}
          </p>
        </div>
      </div>

      {invoice.attachments && (
        <div className="mt-8">
          <div className="flex items-center space-x-3 mb-4">
            <Paperclip className="h-5 w-5 text-gray-400" />
            <h3 className="text-lg font-medium text-gray-900">{t('anlagen')}</h3>
          </div>
          
          {invoice.attachments.length > 0 ? (
            <div className="grid gap-3">
              {invoice.attachments.map((attachment, index) => (
                <div 
                  key={index}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center space-x-3">
                    <Paperclip className="h-4 w-4 text-gray-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        {attachment.filename}
                      </p>
                      <p className="text-xs text-gray-500">
                        {attachment.mimeType}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleDownload(attachment)}
                    className="px-3 py-1 text-sm font-medium text-blue-600 hover:text-blue-500 
                             hover:bg-blue-50 rounded-md transition-colors"
                  >
                    {t('herunterladen')}
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-sm text-gray-500">{t('keineAnlagen')}</p>
          )}
        </div>
      )}
      <div className="mt-8 p-4 bg-blue-50 rounded-lg"><h3 className="font-semibold text-blue-800 mb-2">Möchten Sie mehr Details ansehen und diesen Prozess automatisieren?</h3><p className="text-blue-600 mb-4">Mit Robaws können Sie Ihre Rechnungsverarbeitung, Bestellungen, Lagerbestand und vieles mehr vollständig automatisieren.</p><a href="https://robaws.com/de/demo-anfordern?utm_source=xrechnungzentrale.de" target="_blank" rel="noopener noreferrer" className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium px-6 py-2 rounded-lg transition-colors">Mehr erfahren</a></div>
    </div>
  );
};

export default InvoiceDisplay;